@use 'src/styles/base/colors' as *;

.form-control-slider {
  display: flex;
  align-items: center;
  gap: 0 10px;

  .ngx-slider[disabled] {
    .ngx-slider-selection {
      background-color: $color-A7A6A6;
    }

    .ngx-slider-bar {
      background-color: $color-EDEDED;
    }

    .ngx-slider-pointer {
      border-color: $color-D2D1D1;
      background-color: $color-E7E7E7;
    }
  }

  .ngx-slider {
    width: 160px;
    height: 10px;
    margin: 5px 0;

    .ngx-slider-bar {
      border-radius: 5px;
      height: 10px;
      background-color: $color-D2D1D1;
    }

    .ngx-slider-selection {
      background-color: $color-E30613;
    }

    .ngx-slider-pointer {
      top: -5px;
      width: 20px;
      height: 20px;
      border: 2px solid $color-7D7B7B;
      background-color: $color-FFFFFF;
      box-shadow: 0 2px 10px rgba($color-000000, 0.1);
      outline: none;

      &:after {
        display: none;
      }
    }
  }

  .slider-value {
    display: inline-block;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
