@use 'src/styles/base/colors' as *;

.scale {
  .messages-section {
    textarea {
      &:disabled {
        background-color: $color-FFFFFF;
      }
    }
  }
}

.barcode-scanner {
  .messages-section {
    textarea {
      &:disabled {
        background-color: $color-FFFFFF;
      }
    }
  }
}

.add-edit-correction-process-event-note {
  .textarea {
    border: 0;
  }

  textarea {
    background-color: $color-FFF4D6;
  }
}
