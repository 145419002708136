@use 'src/styles/base/colors' as *;
@use 'src/styles/base/icons' as *;

.form-control-toggle-box {
  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid $color-D2D1D1;
    border-radius: 12px;
    height: 36px;
    overflow: hidden;

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-left: 1px solid $color-D2D1D1;
      cursor: pointer;
      background-color: $color-FFFFFF;

      &:first-child {
        border-left: none;
      }

      &.is-selected {
        background-color: $color-E30613;

        .label {
          color: $color-FFFFFF;
        }
      }

      &.is-disabled {
        cursor: not-allowed;
        background-color: $color-F1F1F1;

        .label {
          color: $color-D2D1D1;
        }
      }

      .label {
        display: block;
        font-weight: 500;
        font-size: 14px;
        color: $color-282525;
        padding: 8px 15px;

        &.is-double-line {
          font-size: 12px;
          text-align: center;
          padding: 0 15px;
        }
      }
    }
  }
}
