@use 'src/styles/base/colors' as *;

$button-default-height: 40px;
$button-default-padding: 0 15px;
$button-default-border-radius: 4px;
$button-default-icon-width: 18px;
$button-default-icon-height: 18px;
$button-icon-default-min-width: 40px;
$button-icon-default-padding: 0;
$button-icon-default-border-radius: 6px;
$button-icon-default-icon-width: 24px;
$button-icon-default-icon-height: 24px;

.button {
  display: inline-flex;
  align-items: center;
  height: $button-default-height;
  padding: $button-default-padding;
  border: 0;
  border-radius: $button-default-border-radius;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;

  &.is-disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &.red {
    background-color: $color-E30613;
    color: $color-FFFFFF;

    &:hover {
      background-color: $color-CD0511;
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-D2D1D1;

      .icon {
        background-color: $color-FFFFFF;
      }
    }

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  &.gray {
    background-color: $color-F1F1F1;
    color: $color-525050;

    &:hover {
      background-color: $color-E7E7E7;
    }

    &.is-active {
      background-color: $color-E30613;
      color: $color-FFFFFF;

      .icon {
        background-color: $color-FFFFFF;
      }
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-D2D1D1;
      color: $color-FFFFFF;

      .icon {
        background-color: $color-FFFFFF;
      }
    }

    .icon {
      background-color: $color-525050;
    }
  }

  &.dark-gray {
    background-color: $color-525050;
    color: $color-FFFFFF;

    &:hover {
      background-color: $color-3F3B3B;
    }

    &.is-disabled,
    &[disabled] {
      background-color: $color-D2D1D1;
      color: $color-FFFFFF;

      .icon {
        background-color: $color-FFFFFF;
      }
    }

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  &.red-outline {
    border: 1px solid $color-E30613;
    background-color: $color-FFFFFF;
    color: $color-E30613;

    &:hover {
      border-color: $color-CD0511;
      background-color: $color-FFF7F7;
      color: $color-CD0511;
    }

    &.is-disabled,
    &[disabled] {
      border-color: $color-D2D1D1;
      background-color: $color-FFFFFF;
      color: $color-D2D1D1;

      .icon {
        background-color: $color-D2D1D1;
      }
    }

    .icon {
      background-color: $color-E30613;
    }
  }

  &.red-link {
    border: none;
    background-color: transparent;
    color: $color-E30613;

    &.is-disabled,
    &[disabled] {
      color: $color-D2D1D1;

      .icon {
        background-color: $color-D2D1D1;
      }
    }

    .icon {
      background-color: $color-E30613;
    }
  }

  &.gray-link {
    border: none;
    background-color: transparent;
    color: $color-7D7B7B;

    &.is-disabled,
    &[disabled] {
      color: $color-D2D1D1;

      .icon {
        background-color: $color-D2D1D1;
      }
    }

    .icon {
      background-color: $color-7D7B7B;
    }
  }

  &.black-outline {
    border: 1px solid $color-525050;
    background-color: $color-FFFFFF;
    color: $color-525050;

    &:hover {
      border-color: $color-282525;
      color: $color-282525;
    }

    &.is-disabled,
    &[disabled] {
      border-color: $color-D2D1D1;
      color: $color-D2D1D1;

      .icon {
        background-color: $color-D2D1D1;
      }
    }

    .icon {
      background-color: $color-525050;
    }
  }

  .icon {
    display: block;
    width: $button-default-icon-width;
    height: $button-default-icon-height;
    mask-size: $button-default-icon-width $button-default-icon-height;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.button-icon {
  @extend .button;

  justify-content: center;
  min-width: $button-icon-default-min-width;
  padding: $button-icon-default-padding;
  border-radius: $button-icon-default-border-radius;
  background-color: $color-F1F1F1;

  &:hover {
    background-color: $color-E7E7E7;
  }

  &.is-active {
    background-color: $color-E30613;

    .icon {
      background-color: $color-FFFFFF;
    }
  }

  .icon {
    display: block;
    width: $button-icon-default-icon-width;
    height: $button-icon-default-icon-height;
    mask-size: $button-icon-default-icon-width $button-icon-default-icon-height;
    background-color: $color-7D7B7B;
  }
}

.mail-link {
  color: $color-E30613;
  cursor: pointer;
  text-decoration: none;
}

.go-to-history-button {
  position: absolute;
  top: 6px;
  right: 20px;
}
