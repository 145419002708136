@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;
@use 'src/styles/forms/inputs' as *;

$dropdown-menu-index: $form-control-dropdown-index;
$dropdown-menu-max-height: 300px;
$dropdown-menu-search-box-height: 40px;

@mixin form-control-dropdown {
  &.is-error {
    .dropdown-toggle {
      border-color: $color-FF3A0F;

      &:focus,
      &.is-open {
        border-color: $color-FF3A0F;
      }
    }

    .search-box {
      .input {
        border-color: $color-D2D1D1;
        color: $color-282525;
      }
    }
  }

  &.is-search {
    .ng-scrollbar {
      max-height: calc(#{$dropdown-menu-max-height} - #{$dropdown-menu-search-box-height});
    }

    .search-box {
      display: block;
    }
  }

  .dropdown-container {
    position: relative;
  }

  .dropdown-toggle {
    @include input;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    background: $color-FFFFFF;

    &:focus {
      border-color: $color-D2D1D1;
    }

    &.is-open {
      .arrow-triangle-down-gray {
        transform: rotate(180deg);
      }

      .dropdown-menu {
        display: block;
      }
    }

    &.button {
      .icon {
        background-color: $color-525050;
      }
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      mask-size: 24px;
      cursor: pointer;
    }

    .close-gray-icon {
      width: 16px;
      height: 16px;
      mask-size: 16px 16px;
      background-color: $color-525050;
    }

    .arrow-triangle-down-gray-icon {
      display: block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      mask-size: 24px 24px;
      background-color: $color-525050;
    }
  }

  .dropdown-toggle-label {
    overflow: hidden;
    display: flex;
    gap: 2px;
    width: 100%;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    &.placeholder {
      color: $color-A7A6A6;
    }
  }

  .dropdown-menu {
    @include dropdown-menu;
  }

  .ng-scrollbar {
    max-height: $dropdown-menu-max-height;
  }

  .search-box {
    display: none;

    .input {
      height: $dropdown-menu-search-box-height;
      padding-right: 36px;
      border-width: 0 0 1px 0;
      border-color: $color-D2D1D1;
      border-radius: 0;
    }
  }

  .search-no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 104px;
    padding: 0 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: $color-A7A6A6;
  }
}

@mixin dropdown-menu-item {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: $color-525050;
  cursor: pointer;

  &.is-selected {
    background-color: $color-E7E7E7;
  }

  &:hover {
    background-color: $color-E7E7E7;
  }
}

@mixin dropdown-menu {
  display: none;
  z-index: $dropdown-menu-index;
  overflow: hidden;
  min-width: 100%;
  max-height: $dropdown-menu-max-height;
  border: 1px solid $color-F1F1F1;
  border-radius: 6px;
  background: $color-FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  &.show {
    display: block;
  }

  .dropdown-menu-item {
    @include dropdown-menu-item;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 40px;
    padding: 10px 14px;
    box-sizing: border-box;
    word-break: break-word;
    color: $color-282525;
  }
}

.form-control-dropdown,
.form-control-dropdown-multiselect {
  @include form-control-dropdown;
}

.form-control-dropdown {
  &.is-error {
    .dropdown-toggle {
      border-color: $color-FF3A0F;

      &:focus,
      &.is-open {
        border-color: $color-FF3A0F;
      }
    }

    .search-box {
      .input {
        border-color: $color-D2D1D1;
        color: $color-282525;
      }
    }
  }

  &.is-inline-label {
    .dropdown {
      min-width: 180px;
    }
  }

  &.is-search {
    .ng-scrollbar {
      max-height: calc(#{$dropdown-menu-max-height} - #{$dropdown-menu-search-box-height});
    }

    .search-box {
      display: block;
    }
  }

  .dropdown-container {
    position: relative;
  }

  .dropdown-toggle {
    @include input;

    display: flex;
    align-items: center;
    background: $color-FFFFFF;

    &:focus {
      border-color: $color-D2D1D1;
    }

    &.is-open {
      .arrow-triangle-down-gray {
        transform: rotate(180deg);
      }

      .dropdown-menu {
        display: block;
      }
    }

    .clear-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    .close-gray-icon {
      width: 16px;
      height: 16px;
      mask-size: 16px 16px;
      background-color: $color-525050;
    }

    .arrow-triangle-down-gray-icon {
      display: block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      mask-size: 24px 24px;
      background-color: $color-525050;
    }
  }

  .dropdown-toggle-label {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .dropdown-menu {
    @include dropdown-menu;
  }

  .ng-scrollbar {
    max-height: $dropdown-menu-max-height;
  }

  .search-box {
    display: none;

    .input {
      height: $dropdown-menu-search-box-height;
      padding-right: 36px;
      border-width: 0 0 1px 0;
      border-color: $color-D2D1D1;
      border-radius: 0;
    }

    .search-gray-icon {
      width: 16px;
      height: 16px;
      mask-size: 16px 16px;
    }
  }

  .search-no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 104px;
    padding: 0 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    color: $color-A7A6A6;
  }
}

.form-control-dropdown-multiselect {
  &.is-options-limit-reached {
    .dropdown-menu-item {
      &:not(.is-selected) {
        cursor: not-allowed;
        color: $color-D2D1D1;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .dropdown-multiselect-selected-options-container {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-right: 6px;
  }

  .selected-options {
    overflow: hidden;
    margin-right: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selected-options-count {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    min-width: 22px;
    padding: 1px 6px;
    border-radius: 4px;
    background: $color-E30613;
    color: $color-FFFFFF;
    font-weight: 700;
  }
}
