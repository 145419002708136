@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;
@use 'src/styles/forms/inputs' as *;

.form-control-datepicker {
  .datepicker {
    @include input;

    height: 36px;
    padding: 13px 16px;
    cursor: pointer;

    &::placeholder {
      font-size: 12px;
    }
  }

  .custom-day {
    text-align: center;
    padding: 6px;
    border-radius: 0.25rem;
    display: inline-block;
    width: 2rem;

    &.hidden {
      display: none;
    }

    &.selected {
      background: $color-E30613 !important;
      color: $color-FFFFFF;
    }

    &:hover,
    &.focused {
      background-color: rgba($color-D2D1D1, 0.1);
      border: 1px solid $color-D2D1D1;
      border-radius: 4px;
      outline: none;
      font-weight: 600;
    }
  }

  .button-icon {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    padding-right: 4px;

    &:hover {
      background: transparent;
    }

    .icon {
      background: $color-A7A6A6;
    }
  }

  .calendar-button {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  .clean-button {
    margin-right: 36px !important;
    z-index: 5;

    .icon {
      width: 20px;
      height: 20px;
      mask-size: 20px 20px;
      background: $color-525050;
    }
  }

  ngb-datepicker {
    z-index: 6;
    border: 1px solid $color-F1F1F1;
    background-color: $color-FFFFFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .custom-select {
      border: none;
      background: transparent;
      text-align: center;
      outline: none;
      cursor: pointer;
    }

    .btn-link {
      cursor: pointer;
    }

    .ngb-dp-week {
      cursor: not-allowed;

      .ngb-dp-day {
        &.disabled {
          color: $color-A7A6A6;
        }
      }
    }
  }

  &.is-error {
    .datepicker-container {
      .datepicker {
        border-color: $color-FF3A0F;
      }
    }
  }

  .datepicker-container {
    position: relative;
  }
}

.ngb-dp-header {
  background-color: $color-F1F1F1 !important;
  border-radius: 10px !important;
  margin: 10px 20px !important;
  padding: 5px 10px !important;

  .ngb-dp-navigation-chevron {
    color: $color-7D7B7B;
  }

  select {
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $color-282525;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &:first-of-type {
      margin-left: 10px;
      text-transform: capitalize;
    }

    &:last-of-type {
      margin-right: 10px;
    }
  }
}

.ngb-dp-month {
  padding: 0 20px 10px;
}

.ngb-dp-weekday {
  text-transform: uppercase;
  font-style: normal !important;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: $color-282525;
  border: none !important;
}

.ngb-dp-arrow-btn {
  font-size: 11px;
}

.datepicker-range-container,
.input-range-container {
  display: flex;
  align-items: center;
  flex: auto;

  span {
    display: flex;
    margin: 0 6px;
    min-width: 8px;
  }
}

.range-label {
  display: block !important;
}

.datepicker-form-control {
  width: 100%;
}
