@use 'src/styles/base/colors' as *;

.form-control-addon-primer-radio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border: 2px solid $color-FFFFFF;
  border-radius: 6px;
  outline: 2px solid transparent;
  cursor: pointer;

  &.is-checked {
    outline-color: $color-E30613;
  }

  &.is-dark-background {
    .primer-label {
      color: $color-FFFFFF;
    }
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  .primer-label {
    line-height: 20px;
    font-weight: 500;
    color: $color-525050;
  }
}
