@use 'src/styles/base/colors' as *;
@use 'src/styles/base/mixins' as *;

.history-gray-icon {
  mask: url('../../assets/icons/history/history-gray-24x24.svg');
  background-color: $color-525050;

  @include icon-size(18px);
}

.globe-gray-24x24 {
  mask: url('../../assets/icons/globe/globe-gray-24x24.svg');
}

.home-gray-icon {
  mask: url('../../assets/icons/home/home-gray-24x24.svg');
}

.tutorials-gray-icon {
  mask: url('../../assets/icons/tutorials/tutorials-gray-30x30.svg');
}

.contractor-gray-icon {
  mask: url('../../assets/icons/users/contractor-gray-24x24.svg');
}

.user-gray-icon {
  mask: url('../../assets/icons/users/user-gray-24x24.svg');
}

.support-gray-icon {
  mask: url('../../assets/icons/support/support-gray-24x24.svg');
}

.logout-gray-icon {
  mask: url('../../assets/icons/close/logout-gray-24x24.svg');
}

.reject-circle-gray-icon {
  mask: url('../../assets/icons/close/reject-circle-gray-24x24.svg');
}

.fix-gray-icon {
  mask: url('../../assets/icons/fix/fix-gray-24x24.svg');
}

.expand-gray-icon {
  mask: url('../../assets/icons/arrows/expand-gray-24x24.svg');
}

.minimalize-gray-icon {
  mask: url('../../assets/icons/arrows/minimalize-gray-24x24.svg');
}

.eye-on-gray-icon {
  mask: url('../../assets/icons/eyes/eye-on-gray-24x24.svg');
}

.eye-off-gray-icon {
  mask: url('../../assets/icons/eyes/eye-off-gray-24x24.svg');
}

.check-gray-icon {
  mask: url('../../assets/icons/check/check-gray-24x24.svg');
}

.drag-indicator-red-14x20-icon {
  mask: url('../../assets/icons/drag-indicator/drag-indicator-red-14x20.svg');
}

.check-red-20x20-icon {
  mask: url('../../assets/icons/check/check-gray-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(20px);
}

.check-black-20x20-icon {
  mask: url('../../assets/icons/check/check-gray-24x24.svg');
  background-color: $color-282525;

  @include icon-size(20px);
}

.chart-gray-24x24-icon {
  mask: url('../../assets/icons/chart/chart-gray-24x24.svg');
}

.arrow-triangle-down-gray-icon {
  mask: url('../../assets/icons/arrows/arrow-triangle-down-gray-24x24.svg');
}

.arrow-triangle-up-gray-icon {
  mask: url('../../assets/icons/arrows/arrow-triangle-up-gray-24x24.svg');
}

.arrow-right-gray-22x22-icon {
  mask: url('../../assets/icons/arrows/arrow-right-gray-22x22.svg');
  background-color: $color-7D7B7B;

  @include icon-size(22px);
}

.arrow-left-gray-24x24-icon {
  mask: url('../../assets/icons/arrows/arrow-left-gray-24x24.svg');
}

.close-gray-icon {
  mask: url('../../assets/icons/close/close-gray-16x16.svg');
}

.close-white-icon {
  mask: url('../../assets/icons/close/close-white-20x20.svg');
}

.close-gray-20x20-icon {
  mask: url('../../assets/icons/close/close-gray-24x24.svg');

  @include icon-size(20px);
}

.close-circle-fill-gray-32x32-icon {
  background-image: url('../../assets/icons/close/close-circle-fill-gray-32x32.svg');

  @include icon-size(32px);
}

.search-gray-icon {
  mask: url('../../assets/icons/search/search-gray-24x24.svg');
}

.search-gray-24x24-icon {
  mask: url('../../assets/icons/search/search-gray-24x24.svg');

  @include icon-size(24px);
}

.calendar-gray-icon {
  mask: url('../../assets/icons/calendars/calendar-gray-24x24.svg');
}

.check-circle-gray-icon {
  mask: url('../../assets/icons/check/check-circle-gray-24x24.svg');
}

.card-gray-icon {
  mask: url('../../assets/icons/cards/card-gray-24x24.svg');
}

.file-empty-gray-icon {
  mask: url('../../assets/icons/files/file-empty-gray-18x18.svg');
}

.file-broken-gray-icon {
  mask: url('../../assets/icons/files/file-broken-gray-24x24.svg');
}

.file-text-white-icon {
  mask: url('../../assets/icons/files/file-text-white-18x18.svg');
}

.file-text-gray-18x18-icon {
  mask: url('../../assets/icons/files/file-text-gray-18x18.svg');
}

.mix-gray-icon {
  mask: url('../../assets/icons/mix/mix-gray-24x24.svg');
}

.mix-gray-24x24-icon {
  mask: url('../../assets/icons/mix/mix-gray-24x24.svg');
}

.tab-gear-gray-icon {
  mask: url('../../assets/icons/settings/tab-gear-gray-24x24.svg');
}

.star-empty-gray-24x24-icon {
  mask: url('../../assets/icons/stars/star-empty-gray-24x24.svg');
}

.star-filled-gray-24x24-icon {
  mask: url('../../assets/icons/stars/star-filled-gray-24x24.svg');
}

.star-filled-yellow-24x24-icon {
  mask: url('../../assets/icons/stars/star-filled-gray-24x24.svg');
  background-color: $color-FFC700;
}

.supplementary-material-gray-icon {
  mask: url('../../assets/icons/materials/supplementary-material-gray-26x26.svg');
}

.search-white-icon {
  mask: url('../../assets/icons/search/search-gray-24x24.svg');
  background-color: $color-FFFFFF;

  @include icon-size(18px);
}

.plus-white-icon {
  mask: url('../../assets/icons/add/plus-white-18x18.svg');
}

.plus-gray-18x18-icon {
  mask: url('../../assets/icons/add/plus-gray-24x24.svg');

  @include icon-size(18px);
}

.plus-green-22x22-icon {
  mask: url('../../assets/icons/add/plus-gray-24x24.svg');
  background-color: $color-0C9D58;

  @include icon-size(22px);
}

.chevron-left-gray-24x24-icon {
  mask: url('../../assets/icons/arrows/chevron-left-gray-24x24.svg');
}

.chevron-right-gray-24x24-icon {
  mask: url('../../assets/icons/arrows/chevron-right-gray-24x24.svg');
}

.chevron-right-white-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/arrows/chevron-right-gray-24x24.svg');
  background-color: $color-FFFFFF;
}

.chevron-right-red-18x18-icon {
  mask: url('../../assets/icons/arrows/chevron-right-gray-24x24.svg');
  background-color: $color-E30613;

  @include icon-size(18px);
}

.chevron-down-gray-24x24-icon {
  mask: url('../../assets/icons/arrows/chevron-down-gray-24x24.svg');

  @include icon-size(24px);
}

.prices-gray-24x24-icon {
  mask: url('../../assets/icons/prices/prices-gray-24x24.svg');

  @include icon-size(24px);
}

.info-circle-gray-icon {
  mask: url('../../assets/icons/infos/info-circle-gray-24x24.svg');
}

.not-available-red-icon {
  mask: url('../../assets/icons/recipes/not-available-red-22x22.svg');
}

.powder-gray-icon {
  mask: url('../../assets/icons/recipes/powder-gray-22x22.svg');
}

.special-gray-icon {
  mask: url('../../assets/icons/recipes/special-gray-22x22.svg');
}

.trash-red-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/remove/trash-gray-24x24.svg');
  background-color: $color-E30613;
}

.trash-red-22x22-icon {
  @include icon-size(22px);

  mask: url('../../assets/icons/remove/trash-gray-24x24.svg');
  background-color: $color-E30613;
}

.trash-gray-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/remove/trash-gray-24x24.svg');
  background-color: $color-525050;
}

.edit-gray-24x24-icon {
  mask: url('../../assets/icons/edit/edit-gray-24x24.svg');
}

.edit-gray-22x22-icon {
  mask: url('../../assets/icons/edit/edit-gray-24x24.svg');
  background-color: $color-7D7B7B;

  @include icon-size(22px);
}

.edit-red-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/edit/edit-gray-24x24.svg');
  background-color: $color-E30613;
}

.download-white-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/download/download-gray-24x24.svg');
  background-color: $color-FFFFFF;
}

.refresh-red-18x18-icon {
  @include icon-size(18px);

  mask: url('../../assets/icons/refresh/refresh-gray-24x24.svg');
  background-color: $color-E30613;
}

.refresh-gray-18x18-icon {
  mask: url('../../assets/icons/refresh/refresh-gray-24x24.svg');

  @include icon-size(18px);
  background-color: $color-525050;
}

.warning-triangle-fill-red-24x24-icon {
  @include icon-size(24px);

  mask: url('../../assets/icons/warnings/warning-triangle-fill-red-24x24.svg');
  background-color: $color-FF3A0F;
}

.warning-triangle-gray-24x24 {
  mask: url('../../assets/icons/warnings/warning-triangle-gray-24x24.svg');
}

.check-circle-fill-green-24x24-icon {
  @include icon-size(24px);

  mask: url('../../assets/icons/check/check-circle-fill-green-24x24.svg');
  background-color: $color-0C9D58;
}

.check-circle-fill-green-32x32-icon {
  mask: url('../../assets/icons/check/check-circle-fill-green-24x24.svg');
  background-color: $color-0C9D58;

  @include icon-size(32px);
}

.scale-gray-24x24-icon {
  @include icon-size(24px);

  mask: url('../../assets/icons/scale/scale-gray-24x24.svg');
  background-color: $color-525050;
}

.spectrophotometer-gray-24x24-icon {
  @include icon-size(24px);

  mask: url('../../assets/icons/spectrophotometer/spectrophotometer-gray-24x24.svg');
  background-color: $color-525050;
}

.barcode-scanner-gray-24x24-icon {
  mask: url('../../assets/icons/barcode-scanner/barcode-scanner-gray-24x24.svg');

  @include icon-size(24px);
  background-color: $color-525050;
}

.user-settings-gray-24x24-icon {
  mask: url('../../assets/icons/settings/user-settings-gray-16x16.svg');

  @include icon-size(24px);
}

.workshop-settings-gray-24x24-icon {
  mask: url('../../assets/icons/settings/settings-workshops-24x24.svg');
}

.refresh-password-18x18-icon {
  mask: url('../../assets/icons/refresh/refresh-password-red-18x18.svg');
}

.addon-primer-gray-24x24-icon {
  mask: url('../../assets/icons/addons/primer-gray-24x24.svg');
}

.addon-clearcoat-gray-24x24-icon {
  mask: url('../../assets/icons/addons/clearcoat-gray-24x24.svg');
}

.target-gray-24x24-icon {
  mask: url('../../assets/icons/target/target-gray-24x24.svg');
}

.label-gray-24x24-icon {
  mask: url('../../assets/icons/label/label-gray-24x24.svg');
}

.disc-empty-gray-24x24-icon {
  mask: url('../../assets/icons/discs/disc-empty-gray-24x24.svg');
}

.disc-filled-gray-24x24-icon {
  mask: url('../../assets/icons/discs/disc-filled-gray-24x24.svg');
  background-color: $color-A7A6A6;
}

.disc-filled-gray-24x24-icon {
  mask: url('../../assets/icons/discs/disc-filled-gray-24x24.svg');
  background-color: $color-525050;
}

.tools-gray-24x24-icon {
  mask: url('../../assets/icons/fix/tools-gray-24x24.svg');
}

.hardener-special-24x24 {
  mask: url('../../assets/icons/hardener-special/hardener-special-24x24.svg');
}

.thinner-special-24x24 {
  mask: url('../../assets/icons/thinner-special/thinner-special-24x24.svg');
}

.warning-octagon-gray-24x24-icon {
  mask: url('../../assets/icons/warnings/warning-octagon-gray-24x23.svg');
}

.envelope-red-24x24-icon {
  mask: url('../../assets/icons/envelope/envelope-red-24x24.svg');

  @include icon-size(24px);
  background-color: $color-E30613;
}

.x-circle-gray-24x24-icon {
  mask: url('../../assets/icons/x-circle/x-circle-gray-24x24.svg');
}

.novol-white-icon {
  mask: url('../../assets/icons/novol/novol-white-20x20.svg');
}

.paper-plane-tilt-icon {
  mask: url('../../assets/icons/paper-plane-tilt/paper-plane-tilt-gray-24x24.svg');
}

.tree-structure-icon {
  mask: url('../../assets/icons/tree-structure/tree-structure-24x24.svg');
}

.exclamation-gray-icon {
  mask: url('../../assets/icons/exclamation/exclamation-gray-24x24.svg');
}
