@use 'src/styles/base/colors' as *;

.form-control-slider-with-addon-range-labels {
  display: flex;
  align-items: center;
  gap: 0 10px;

  .slider-value {
    display: inline-block;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
