@use 'src/styles/base/colors' as *;
@use 'src/styles/base/mixins' as *;

.modal {
  .choose-contractor-modal,
  .choose-fix-modal,
  .choose-spectrophotometer-measurement-modal,
  .mixing-split-assign-to-fix-modal {
    .modal-container {
      .modal-content {
        padding: 4px 20px;

        .modal-scrollbar {
          max-height: calc(100vh - 246px) !important;
          height: 424px !important;
        }

        .ng-scroll-content {
          display: flex;
          min-height: 100%;
        }
      }
    }
  }

  .recipe-details-content {
    .ng-scroll-content {
      max-height: calc(100vh - 300px) !important;
      padding-right: 20px !important;
    }

    .price-ingredients-table,
    .ingredients-table {
      .ng-scroll-content {
        padding-right: 0 !important;
      }

      .ng-scrollbar {
        height: unset;
      }
    }

    .dropdown-menu {
      .ng-scroll-content {
        padding-right: 0 !important;
      }
    }
  }

  .mixing-confirm-finish-mixing-modal {
    .content {
      span {
        font-weight: 700;
        color: $color-282525;
      }
    }
  }

  .fix-basic-data-add-edit-modal {
    .form {
      @include modal-form-container;
    }

    .footer {
      @include modal-form-footer;
    }
  }
}

body.modal-open {
  .modal-backdrop.show {
    visibility: hidden;

    &:last-of-type {
      visibility: visible;
    }
  }

  .modal.show {
    visibility: hidden;

    &:last-of-type {
      visibility: visible;
    }
  }
}

.recipe-details-base-modal {
  .unexpected-error,
  .no-recipe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 55px;

    &.border {
      background: $color-FFFFFF;
      border: 1px solid $color-E7E7E7;
    }
  }
}
