@use 'variables' as *;

body,
button,
textarea,
input {
  font-family: $default-font-family;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  color: $default-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  @extend .title-1;
}

h2 {
  @extend .title-2;
}

h3 {
  @extend .title-3;
}

h4 {
  @extend .title-4;
}

h5 {
  @extend .title-5;
}

h6 {
  @extend .title-6;
}

.title-1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}

.title-2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.title-3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
}

.title-4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.title-5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.title-6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.subtitle-1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.subtitle-2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.body-1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.body-3 {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.caption-1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.caption-2 {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
