@use 'src/styles/base/colors' as *;

@mixin choose-modal {
  .search-box {
    display: block;
    margin-top: 14px;
  }

  .choose-list {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 4px;
  }

  .loader,
  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

@mixin details-row-box($columns-number) {
  position: relative;
  display: grid;
  grid-template-columns: repeat($columns-number, 1fr);
  align-items: flex-start;
  gap: 0 20px;
  width: 100%;
  min-height: 65px;
  padding: 10px 20px 10px 32px;
  font-size: 14px;

  &.is-selected {
    border-radius: 10px;
    background-color: $color-F8F8F8;
  }

  &:before {
    position: absolute;
    left: 14px;
    top: 50%;
    content: '';
    height: 20px;
    width: 4px;
    border-radius: 10px;
    background-color: $color-E30613;
    transform: translateY(-50%);
  }

  &.is-button-delete {
    padding-right: 60px;

    .button-delete {
      display: flex;
    }
  }

  .column {
    display: flex;
    flex-flow: column;
    font-weight: 500;

    .label {
      display: block;
      margin-bottom: 6px;
      line-height: 17px;
      color: $color-525050;
    }

    .value {
      display: block;
      line-height: 22px;
      word-break: break-word;
      color: $color-282525;
    }
  }

  .button-delete {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 1;
    transform: translateY(-50%);
    display: none;
    background-color: transparent;

    .close-gray-20x20-icon {
      width: 20px;
      height: 20px;
      mask-size: 20px 20px;
      margin: 0;
      background-color: $color-7D7B7B;
    }
  }
}

@mixin modal-form-container {
  .form-section {
    padding-left: 20px;
    padding-right: 20px;

    &:first-child {
      padding-bottom: 20px;
      border-bottom: 1px solid $color-E7E7E7;
    }

    &:not(:first-child) {
      padding-top: 20px;
    }

    .form-control {
      ::ng-deep {
        .input-suffix {
          width: 80px;
        }
      }
    }

    .form-section-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $color-282525;
      margin-bottom: 20px;
    }

    .form-section-fields {
      grid-template-columns: repeat(4, minmax(275px, 1fr));
      grid-gap: 20px;
      display: grid;
    }
  }
}

@mixin modal-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .buttons {
    display: flex;
    align-items: center;
  }

  .button {
    &:first-child {
      margin-right: 10px;
    }
  }
}

@mixin icon-size($width, $height: $width) {
  width: $width;
  height: $height;
  mask-size: $width $height;
}

@mixin tab {
  position: relative;
  box-sizing: border-box;

  &:first-child {
    margin-left: 0;
  }

  .tab-button {
    cursor: pointer;
    outline: none;
    height: unset;
    padding: 0;
    border: none;
    margin: 0;

    &[disabled] {
      cursor: not-allowed;
    }

    .label {
      display: flex;
      justify-content: space-between;
      padding: 17px 0;
      border-bottom: 2px solid transparent;
      font-weight: 500;
    }

    .icon {
      margin-right: 0;
    }

    &:not(.disabled, .active, .show):hover {
      .label {
        border-color: $color-E7E7E7;
      }
    }
  }

  &.show,
  &.active {
    .tab-button {
      .label {
        border-color: $color-E30613;
      }
    }
  }

  &.active {
    .tab-button {
      .label {
        color: $color-282525;
      }
    }

    .button {
      pointer-events: none;
      outline: none;
    }
  }

  .underline {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    margin: 0;
    border: 1px solid transparent;
  }

  .dropdown {
    transform: translate(0, 60px) !important;

    .button:disabled {
      color: $color-D2D1D1;
    }
  }
}

@mixin auth-page-layout {
  .auth-page-layout {
    display: flex;
    flex-flow: column;
    align-items: center;

    .logo-car-color {
      width: 230px;
      margin-bottom: 30px;
    }

    .form {
      display: block;
      width: 100%;
    }

    .subtitle-1 {
      width: 252px;
      color: $color-7D7B7B;
      margin: 15px 0 25px;
      text-align: center;
    }

    .red {
      width: 200px;
    }

    .navigate-button {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $color-282525;
      margin-top: 20px;
      background: transparent;
      height: 100%;
    }
  }
}

@mixin box {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid $color-E7E7E7;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  background-color: $color-FFFFFF;
}
