@use 'src/styles/base/colors' as *;

.modal {
  .modal-content {
    &.has-content-side-padding {
      .modal-scrollbar {
        .ng-scrollbar-wrapper[deactivated='false'] {
          & > scrollbar-y {
            &.scrollbar-control {
              right: -20px;
            }
          }
        }
      }
    }
  }
}

.page-layout-main-scrollbar {
  .page-layout-main-viewport {
    > .ng-scroll-content {
      display: grid;
      min-height: 100%;
    }
  }
}

.ng-scrollbar-wrapper {
  & > scrollbar-y {
    &.scrollbar-control {
      & > .ng-scrollbar-track {
        & > .ng-scrollbar-thumb {
          background-color: $color-E7E7E7;
        }
      }
    }
  }
}
