@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;

$padding: 20px;

@mixin font-size-small {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
}

@mixin font-size-medium-large {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

body {
  &.modal-open {
    padding: 0 !important;
  }
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-content-index;
  overflow-y: auto;
  display: none;
  justify-content: center;
  align-items: center;
  padding: $padding;

  &.show {
    display: flex;
  }

  &.small {
    .modal-dialog {
      max-width: 400px;
    }

    .modal-container {
      .modal-header {
        padding-bottom: 10px;

        .modal-title {
          @include font-size-small;
        }
      }

      .modal-content {
        border: none !important;

        &.has-content-side-padding {
          padding: 0 $padding;
        }
      }

      .modal-footer {
        border: none;
      }
    }
  }

  &.medium {
    .modal-dialog {
      max-width: 800px;
    }
  }

  &.large {
    .modal-dialog {
      max-width: 1200px;
    }
  }

  .modal-container {
    &.is-header {
      .modal-header {
        display: block;
      }
    }

    &.is-footer {
      .modal-footer {
        display: flex;
      }
    }

    &.is-header {
      .modal-content {
        border-top: 1px solid $color-E7E7E7;
      }
    }

    &.is-footer {
      .modal-content {
        border-bottom: 1px solid $color-E7E7E7;
      }
    }

    .modal-header {
      display: none;
      box-sizing: border-box;
      padding: $padding;

      .modal-title {
        @include font-size-medium-large;
      }
    }

    .modal-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $color-7D7B7B;

      &.has-content-side-padding {
        padding-left: $padding;
        padding-right: $padding;
      }

      &.has-content-vertical-padding {
        padding-top: $padding;
        padding-bottom: $padding;
      }

      .modal-scrollbar {
        max-height: calc(100vh - 228px) !important;
      }
    }

    .modal-footer {
      display: none;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: $padding;
    }
  }
}

.modal-dialog {
  background-color: $color-FFFFFF;
  border: 1px solid #f1f1f1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: content-box;
  width: 100%;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: $modal-backdrop-index;
}
