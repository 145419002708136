@use 'src/styles/base/colors' as *;

.search-box {
  position: relative;

  .input {
    height: 36px;
    padding-right: 44px;
  }

  .search-gray-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: block;
    width: 24px;
    height: 24px;
    mask-size: 24px 24px;
    background-color: $color-525050;
    pointer-events: none;
  }
}
