@use 'src/styles/base/colors' as *;
@use 'src/styles/base/icons' as *;
@use 'src/styles/forms/dropdowns' as *;
@use 'src/styles/base/mixins' as *;

.form-control-effect-type {
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $color-D2D1D1;
    border-radius: 12px;
    height: 36px;
    overflow: hidden;

    .option {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-left: 1px solid $color-D2D1D1;
      cursor: pointer;

      &:first-child {
        border-left: none;
      }

      &.is-selected {
        background-color: $color-E30613;

        .label {
          color: $color-FFFFFF;

          .icon {
            background-color: $color-FFFFFF;
          }
        }
      }

      &.is-disabled {
        cursor: not-allowed;
        background-color: $color-F1F1F1;

        .label {
          color: $color-D2D1D1;
        }
      }

      .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: $color-A7A6A6;

        .icon {
          display: block;
          @include icon-size(16px);
          background-color: $color-525050;
          margin-top: 2px;
        }
      }
    }
  }

  .dropdown-menu {
    @include dropdown-menu;

    min-width: 170px;
  }
}
