@use 'colors' as *;

$default-font-family: 'Lato', sans-serif;
$default-font-size: 14px;
$default-font-weight: 500;
$default-font-color: $color-282525;

$default-gradient: linear-gradient(95.8deg, $color-EEEEEE 0%, $color-FAFAFA 100%);

$form-control-dropdown-index: 999;
$form-control-autocomplete-index: 999;
$form-control-min-width: 100px;
$form-control-max-width: 250px;
$user-dropdown-index: 999;
$modal-backdrop-index: 1055;
$modal-content-index: 1056;
$loder-index: 1057;

$page-header-height: 60px;
$table-row-cell-min-height: 60px;
$table-column-min-width: 100px;
$table-column-first-last-max-width: 280px;
$table-column-max-width: 270px;
$table-details-column-min-width: 100px;
$table-details-column-max-width: 250px;
$table-details-column-gap: 15px 20px;
