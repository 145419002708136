@use 'src/styles/base/colors' as *;

.prepare-mix-form {
  .amount {
    .input {
      max-width: 147px;
    }
  }
}

.mixing-ingredient-form {
  .form-control-current-amount {
    .input {
      max-width: 139px;
    }
  }
}

.add-materials-form {
  .materials {
    .dropdown-container {
      min-width: 400px;
    }
  }
}

.mixing-split-form {
  .input-wrapper {
    &.is-suffix {
      .input {
        padding-right: 20px;
      }
    }
  }
}

.add-ingredient-form {
  .ingredient {
    .dropdown-container {
      min-width: 394px;
    }
  }

  .amount {
    .input-wrapper {
      min-width: 192px;
    }
  }
}

.measuring-angles-form {
  .form-control-checkbox {
    &.is-disabled {
      .checkbox {
        .label {
          color: $color-282525;
        }
      }

      .checkbox {
        &:before {
          background: $color-E30613 url('../../assets/icons/check/check-white-10x6.svg') no-repeat center center;
        }
      }
    }
  }
}
