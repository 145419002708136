@use 'src/styles/base/colors' as *;

.measuring-angles-form,
.color-systems {
  .form-control-checkbox {
    &.is-disabled {
      .label {
        color: $color-525050;
      }

      .checkbox {
        &:before {
          background: $color-E30613 url('../../assets/icons/check/check-white-10x6.svg') no-repeat center center;
        }
      }
    }
  }
}
