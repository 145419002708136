@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/inputs' as *;

.form-control-textarea {
  height: 100%;

  &.is-focus {
    .textarea {
      border-color: $color-E30613;
    }
  }
}

.textarea {
  display: block;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid $color-A7A6A6;
  height: 100%;
}

textarea {
  @include input;

  width: 100%;
  height: 100%;
  border: 0;
  padding: 8px 10px;
  line-height: 20px;
  resize: none;
}
