@use 'src/styles/base/colors' as *;
@use 'src/styles/base/variables' as *;

.dropdown-toggle {
  position: relative;

  .dropdown {
    display: none;
    min-width: 200px;
    position: absolute;
    right: 0;
    z-index: $user-dropdown-index;
    background-color: $color-FFFFFF;
    border: 1px solid $color-F1F1F1;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    &.show {
      display: block;
    }

    .button {
      height: 40px;
      width: 100%;
      background-color: $color-FFFFFF;

      &:hover {
        background-color: $color-F8F8F8;
      }

      .icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        mask-size: 20px 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.recipe-details-modal {
  .recipe-details-header {
    .form-control-dropdown {
      .form-control {
        flex-direction: row;
        align-items: center;

        label {
          margin-right: 10px;
        }

        .dropdown {
          width: 195px;
        }
      }
    }
  }
}
