$color-E30613: #e30613;
$color-FFF7F7: #fff7f7;
$color-CD0511: #cd0511;
$color-000000: #000000;
$color-282525: #282525;
$color-3F3B3B: #3f3b3b;
$color-525050: #525050;
$color-5C5C5C: #5c5c5c;
$color-7D7B7B: #7d7b7b;
$color-A7A6A6: #a7a6a6;
$color-D2D1D1: #d2d1d1;
$color-E7E7E7: #e7e7e7;
$color-F1F1F1: #f1f1f1;
$color-F8F8F8: #f8f8f8;
$color-FBFBFB: #fbfbfb;
$color-FFFFFF: #ffffff;
$color-0761A3: #0761a3;
$color-40AFFF: #40afff;
$color-BBE2FF: #bbe2ff;
$color-0C9D58: #0c9d58;
$color-E0F4EA: #e0f4ea;
$color-ECF8F2: #ecf8f2;
$color-FF3A0F: #ff3a0f;
$color-FFF5F3: #fff5f3;
$color-FFC700: #ffc700;
$color-EEEEEE: #eeeeee;
$color-FAFAFA: #fafafa;
$color-FFD978: #ffd978;
$color-FFF4D6: #fff4d6;
$color-FFD4C7: #ffd4c7;
$color-EAF3FA: #eaf3fa;
$color-EDEDED: #ededed;
$color-FFF6F6: #fff6f6;
$color-F29197: #f29197;
$color-282525-alpha-04: rgba($color-282525, 0.4);
