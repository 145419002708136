@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/inputs';
@use 'src/styles/forms/textareas';
@use 'src/styles/forms/passwords';
@use 'src/styles/forms/checkboxes';
@use 'src/styles/forms/toggles';
@use 'src/styles/forms/dropdowns';
@use 'src/styles/forms/autocompletes';
@use 'src/styles/forms/datepickers';
@use 'src/styles/forms/radios';
@use 'src/styles/forms/toggleboxes';
@use 'src/styles/forms/effecttypes';
@use 'src/styles/forms/addon-primer-radios';
@use 'src/styles/forms/sliders';
@use 'src/styles/forms/sliders_with_addon-range-labels';

.form-control {
  display: flex;
  flex-direction: column;

  &.is-label {
    .label {
      display: block;

      &.is-required:after {
        content: '*';
        color: $color-E30613;
        margin-left: 3px;
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }

  &.is-inline-label {
    flex-direction: row;
    align-items: flex-start;

    .label {
      margin-right: 10px;
      margin-top: 8px;
    }
  }

  &.is-error {
    .input,
    .textarea {
      border-color: $color-FF3A0F;
      color: $color-FF3A0F;
    }

    .label {
      color: $color-FF3A0F;
    }

    .errors-list {
      display: block;
    }
  }

  &.is-withdraw {
    .dropdown-toggle-label {
      color: $color-A7A6A6;
    }
  }

  &.is-thinner-or-hardener {
    .dropdown-toggle-label {
      color: $color-E30613;
    }
  }

  &.meter {
    .input-wrapper.is-suffix {
      .input {
        padding-right: 65px;
      }
    }

    .input-suffix {
      width: 65px;
    }
  }

  .label {
    display: none;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: $color-525050;
  }

  .errors-list {
    display: none;
  }
}
