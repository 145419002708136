@use 'src/styles/base/colors' as *;

@mixin input {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 0 10px;
  border-radius: 12px;
  border: 1px solid $color-A7A6A6;
  background-color: $color-FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  color: $color-282525;
  outline: 0;

  &::placeholder {
    color: $color-A7A6A6;
  }

  &:focus {
    border-color: $color-E30613;
  }

  &:disabled {
    border-color: $color-D2D1D1;
    background-color: $color-F1F1F1;
    color: $color-A7A6A6;
    cursor: not-allowed;
  }
}

@mixin input-wrapper {
  position: relative;

  &.is-suffix {
    .input {
      padding-right: 60px;
    }

    .input-suffix {
      display: flex;
      width: fit-content;
    }
  }

  .input-suffix {
    display: none;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 60px;
    padding: 0 10px;
    border-radius: 0 12px 12px 0;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: $color-A7A6A6;
    pointer-events: none;
  }
}

input[type='text'],
input[type='number'],
input[type='phone'],
input[type='email'],
input[type='password'] {
  @include input;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-control-input {
  .input-wrapper {
    @include input-wrapper;
  }
}

.input-range-container {
  display: flex;
  align-items: center;
  flex: auto;

  span {
    display: flex;
    margin: 0 6px;
    min-width: 8px;
  }

  .form-control {
    width: 100%;
  }
}

.input-label {
  display: block !important;
}
