@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/inputs' as *;

.form-control-password {
  &.is-visible {
    .button-toggle-visibility {
      .eye-on-gray-icon {
        display: none;
      }

      .eye-off-gray-icon {
        display: block;
      }
    }
  }

  .input-wrapper {
    @include input-wrapper;

    .input-suffix {
      display: flex;
      padding: 0;
    }
  }

  .button-toggle-visibility {
    width: 100%;
    height: 100%;
    min-width: auto;
    background-color: $color-FFFFFF;
    pointer-events: auto;

    &:hover {
      background-color: $color-FFFFFF;
    }

    .icon {
      justify-content: center;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    .eye-on-gray-icon,
    .eye-off-gray-icon {
      width: 24px;
      height: 24px;
      margin: 0;
    }

    .eye-on-gray-icon {
      display: block;
    }

    .eye-off-gray-icon {
      display: none;
    }
  }
}
