@use 'src/styles/base/colors' as *;

.tooltip {
  color: $color-FFFFFF;
  background-color: rgba($color-282525, 0.9);
  padding: 8px 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  white-space: pre-line;

  &.bs-tooltip-start,
  &.bs-tooltip-end,
  &.bs-tooltip-top,
  &.bs-tooltip-bottom {
    padding: 8px 10px;
  }
}

.mixing-finish-button-close-tooltip {
  margin-bottom: 5px !important;
}
