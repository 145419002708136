@use 'src/styles/base/variables' as *;
@use 'src/styles/base/colors' as *;

app-table-row {
  &.is-selected {
    app-table-row-cell {
      background-color: $color-FFF7F7;
      border-color: $color-E30613;
    }
  }

  &.is-success {
    app-table-row-cell {
      border-color: $color-0C9D58;
      background-color: $color-E0F4EA;
    }
  }

  &.selectable {
    app-table-row-cell:hover {
      cursor: pointer;
    }
  }

  app-table-row-cell {
    border-width: 1px 0;
    border-style: solid;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    &:first-child {
      border-left-width: 1px;
    }

    &:last-child {
      border-right-width: 1px;
    }
  }
}

.table-content.can-toggle {
  > app-table-row {
    > app-table-row-cell {
      cursor: pointer;
    }
  }
}

.correction-process-group-table {
  .table-header,
  .table-content {
    grid-template-columns:
      290px
      minmax($table-column-min-width, 150px)
      minmax($table-column-min-width, $table-column-first-last-max-width)
      repeat(2, minmax($table-column-min-width, 150px))
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.contractors-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, $table-column-first-last-max-width)
      repeat(2, minmax($table-column-min-width, $table-column-max-width))
      1fr
      auto;
  }
}

.fixes-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, 145px)
      repeat(2, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, $table-column-max-width)
      repeat(3, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.recipes-favorite-table {
  .table-header,
  .table-content {
    grid-template-columns:
      60px
      minmax($table-column-min-width, 250px)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, 250px)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, 250px)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.own-recipes-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, 220px)
      minmax($table-column-min-width, 135px)
      repeat(3, minmax($table-column-min-width, 220px))
      repeat(2, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.own-recipe-table-details-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax($table-column-min-width, 400px)
      minmax($table-column-min-width, 135px)
      auto;
  }
}

.workshop-users-table {
  .table-header,
  .table-content {
    grid-template-columns:
      repeat(2, minmax($table-column-min-width, 250px))
      repeat(2, minmax($table-column-min-width, 115px))
      minmax($table-column-min-width, 250px)
      repeat(2, minmax($table-column-min-width, 115px))
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.mixing-history-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      repeat(3, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, $table-column-max-width)
      repeat(2, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 180px)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.mixing-history-details-list {
  .table-header,
  .table-content {
    grid-template-columns:
      repeat(6, minmax($table-column-min-width, 135px))
      auto;
  }
}

.prices-table {
  .table-header,
  .table-content {
    grid-template-columns:
      60px
      auto
      repeat(6, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 155px);
  }
}

.recipe-linked-colors-table {
  .table-header,
  .table-content {
    grid-template-columns:
      135px
      minmax($table-column-min-width, 120px)
      minmax($table-column-min-width, 290px)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.correction-process-group-details-correction-process-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 135px)
      repeat(2, minmax($table-column-min-width, 1fr))
      auto;
  }
}

.recipe-combination-colors-table {
  .table-header,
  .table-content {
    grid-template-columns:
      135px
      minmax($table-column-min-width, 120px)
      minmax($table-column-min-width, 290px)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.recipes-table {
  &.is-measurement-filter {
    .table-header,
    .table-content {
      grid-template-columns:
        60px
        100px
        repeat(7, minmax($table-column-min-width, 135px))
        100px
        60px
        1fr;
    }

    app-table-header {
      app-table-header-cell {
        &:last-child {
          div {
            width: 100%;
            text-align: right;
          }
        }
      }
    }

    app-table-row {
      app-table-row-cell {
        &:last-child {
          div.table-row-cell {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .table-header,
  .table-content {
    grid-template-columns:
      60px
      100px
      repeat(2, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 1fr)
      repeat(3, minmax($table-column-min-width, 135px))
      minmax($table-column-min-width, 1fr);
  }

  app-table-row {
    &.selected {
      &:nth-child(6) {
        app-table-row-cell {
          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}

.ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax(385px, 385px)
      auto;
  }

  &.has-correction {
    .table-header,
    .table-content {
      grid-template-columns:
        120px
        minmax(385px, 385px)
        250px
        auto;
    }
  }

  .table {
    border: none !important;
    overflow: visible !important;

    .table-header-cell {
      font-weight: 500;
      font-size: 14px;
      color: $color-7D7B7B;
      min-height: 30px;
    }

    .table-row-cell {
      color: $color-282525;
    }
  }

  .table-scrollbar {
    border: 1px solid $color-E7E7E7;
    border-radius: 10px;
  }

  .table-content {
    overflow: hidden;

    app-table-row:last-child {
      app-table-row-cell {
        border-bottom: none;
      }
    }
  }
}

.price-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      1fr
      repeat(2, 250px);
  }

  .table {
    border: none !important;
    overflow: visible !important;

    .table-header-cell {
      font-weight: 500;
      font-size: 14px;
      color: $color-7D7B7B;
      min-height: 30px;
    }

    .table-row-cell {
      color: $color-282525;
    }
  }

  .table-scrollbar {
    border: 1px solid $color-E7E7E7;
    border-radius: 10px;
  }

  .table-content {
    overflow: hidden;

    app-table-row:last-child {
      app-table-row-cell {
        border-bottom: none;
      }
    }
  }
}

.favorites-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax(385px, 385px)
      250px
      auto;
  }
}

.materials-table {
  height: 100%;

  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax(385px, 385px)
      minmax(250px, auto)
      repeat(4, minmax(135px, auto))
      minmax($table-column-min-width, 1fr);
  }
}

.mixtures-table {
  height: 100%;

  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 135px)
      minmax($table-column-min-width, 1fr)
      auto;
  }
}

.mixtures-list-item-details-table {
  .table-header,
  .table-content {
    grid-template-columns:
      120px
      minmax(385px, 385px)
      minmax($table-column-min-width, $table-column-max-width)
      repeat(3, minmax(135px, auto))
      minmax($table-column-min-width, 1fr);
  }
}

.mixtures-other-list-item-details-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax(385px, 385px)
      minmax($table-column-min-width, $table-column-max-width)
      repeat(3, minmax(135px, auto))
      minmax($table-column-min-width, 1fr);
  }
}

.ingredient-table {
  .table-header,
  .table-content {
    grid-template-columns:
      60px
      100px
      minmax($table-column-min-width, 1fr)
      repeat(2, minmax($table-column-min-width, 270px));
  }
}

.spectrophotometer-measurements-table {
  .table-header,
  .table-content {
    grid-template-columns:
      60px
      105px
      repeat(2, minmax($table-column-min-width, 250px))
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 250px)
      minmax($table-column-min-width, 1fr)
      60px;
  }
}

.mixing-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      50px
      105px
      1fr
      repeat(3, minmax($table-column-min-width, $table-column-max-width));
  }
}

.added-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      60px
      100px
      auto
      repeat(2, minmax($table-column-min-width, $table-column-max-width))
      minmax($table-column-min-width, 155px);
  }
}

.incorrect-data-table {
  .table-header,
  .table-content {
    grid-template-columns: repeat(2, minmax($table-column-min-width, 135px)) 1fr;
  }
}

.mixtures-other-list-item-details-table,
.mixtures-list-item-details-table,
.mixing-history-details-list,
.own-recipe-table-details-ingredients-table,
.favorites-ingredients-table,
.formula-ingredients-table,
.recipe-linked-colors-table,
.correction-process-group-details-correction-process-table,
.recipe-combination-colors-table {
  .table {
    border: none !important;
    background-color: transparent !important;

    .table-header-cell {
      background-color: transparent;
      font-weight: 500;
      font-size: 14px;
      color: $color-7D7B7B;
      min-height: 30px;
    }

    .table-content {
      border: 1px solid $color-E7E7E7;
      border-radius: 10px;
      overflow: hidden;

      app-table-row {
        app-table-row-cell {
          background-color: $color-FFFFFF;
          color: $color-282525;
        }

        &:last-child {
          app-table-row-cell {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.incorrect-data-table {
  .table {
    border: none !important;
    background-color: transparent !important;

    .table-header-cell {
      background-color: transparent;
      font-weight: 500;
      font-size: 14px;
      color: $color-525050;
      min-height: 22px;

      &:first-child {
        padding-left: 0;
      }
    }

    .table-content {
      border-radius: 10px;
      overflow: hidden;

      app-table-row {
        app-table-row-cell {
          color: $color-282525;
          min-height: 22px;
          border: none;

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.material-report-list-item-details-table {
  .table {
    border: none !important;
    background-color: $color-F8F8F8 !important;
  }

  .table-content {
    overflow: hidden;

    app-table-row {
      app-table-row-cell {
        background-color: $color-F8F8F8;
        color: $color-282525;
        border-bottom: 1px solid $color-E7E7E7;
      }

      &:first-child {
        app-table-row-cell {
          border-top: 1px solid $color-E7E7E7;
        }
      }
    }
  }
}

.operation-history-table {
  .table-header,
  .table-content {
    grid-template-columns:
      repeat(4, minmax($table-column-min-width, $table-column-max-width))
      minmax($table-column-min-width, 1fr)
      auto;

    .buttons {
      display: none;
    }
  }
}

.addons-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      50px
      105px
      1fr
      repeat(2, minmax($table-column-min-width, $table-column-max-width));
  }
}

.color-issues-table {
  .table-header,
  .table-content {
    grid-template-columns:
      minmax($table-column-min-width, 150px)
      minmax($table-column-min-width, $table-column-max-width)
      minmax($table-column-min-width, 150px)
      repeat(3, minmax($table-column-min-width, $table-column-max-width))
      minmax($table-column-min-width, 150px)
      minmax($table-column-min-width, $table-column-max-width)
      auto;
  }
}

.formula-ingredients-table {
  .table-header,
  .table-content {
    grid-template-columns:
      105px
      auto
      repeat(2, minmax($table-column-min-width, $table-column-max-width));
  }
}

.are-checkboxes-in-first-row-cell-hidden {
  app-table-header {
    app-table-header-cell:first-child {
      div {
        display: none;
      }
    }
  }

  app-table-row {
    app-table-row-cell:first-child {
      div {
        display: none;
      }
    }
  }
}

.is-toggle-in-last-row-cell-hidden {
  app-table-row {
    app-table-row-cell:last-child {
      .button-toggle {
        display: none;
      }
    }
  }
}
