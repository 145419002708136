@use 'src/styles/base/colors' as *;
@use 'src/styles/base/icons' as *;

.form-control-radio {
  &.is-checked {
    .radio {
      &:before {
        border-color: $color-E30613;
        background: url('../../assets/icons/circles/circles-red-10x10.svg') no-repeat center center;
      }
    }
  }

  &.is-disabled {
    .radio {
      cursor: not-allowed;

      &:before {
        border-color: $color-D2D1D1;
      }

      .label {
        color: $color-A7A6A6;
      }
    }
  }

  .radio {
    position: relative;
    display: flex;
    cursor: pointer;

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-FFFFFF;
      border: 2px solid $color-7D7B7B;
    }

    .label {
      display: inline-block;
      padding-left: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;
      color: $color-282525;
    }
  }

  .input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }

  > .errors-list {
    padding-left: 30px;
  }
}
