@use 'src/styles/base/colors' as *;
@use 'src/styles/base/icons' as *;

@mixin checkbox {
  position: relative;
  display: flex;
  cursor: pointer;

  &:before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 1px;
    border: 1px solid $color-7D7B7B;
    background-color: $color-E7E7E7;
  }
}

@mixin checkbox-checked {
  &:before {
    background: $color-E30613 url('../../assets/icons/check/check-white-10x6.svg') no-repeat center center;
    border: $color-E30613;
  }
}

@mixin checkbox-indeterminate {
  &:before {
    background: $color-E30613 url('../../assets/icons/check/check-indeterminate-white-12x2.svg') no-repeat center center;
    border-color: $color-E30613;
  }
}

@mixin checkbox-disabled {
  cursor: not-allowed;

  &:before {
    background: $color-F1F1F1 url('../../assets/icons/close/close-gray-16x16.svg') no-repeat center center;
    border: 1px solid $color-D2D1D1;
  }
}

@mixin checkbox-checked-disabled {
  cursor: not-allowed;

  &:before {
    background: $color-7D7B7B url('../../assets/icons/check/check-white-10x6.svg') no-repeat center center;
    border: 1px solid $color-7D7B7B;
  }
}

@mixin checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.form-control-checkbox {
  &.is-checked {
    .checkbox {
      @include checkbox-checked;
    }
  }

  &.is-indeterminate {
    .checkbox {
      @include checkbox-indeterminate;
    }
  }

  &.is-disabled {
    .checkbox {
      @include checkbox-disabled;

      .label {
        color: $color-A7A6A6;
      }
    }
  }

  &.is-checked.is-disabled {
    .checkbox {
      @include checkbox-checked-disabled;
    }
  }

  &.is-label {
    .label {
      padding-left: 10px;
    }
  }

  .checkbox {
    @include checkbox;

    .label {
      display: inline-block;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      user-select: none;
      color: $color-282525;
    }
  }

  .input {
    @include checkbox-input;
  }

  > .errors-list {
    padding-left: 30px;
  }
}
