@use 'src/styles/base/variables' as *;
@use 'src/styles/base/colors' as *;
@use 'src/styles/forms/inputs' as *;
@use 'src/styles/forms/dropdowns' as *;

$autocomplete-menu-index: $form-control-autocomplete-index;
$autocomplete-menu-max-height: 300px;

.form-control-autocomplete {
  .input-wrapper {
    @include input-wrapper;

    &.is-open {
      .dropdown-menu {
        display: block;
      }
    }
  }

  .dropdown {
    position: relative;
  }

  .dropdown-menu {
    @include dropdown-menu;

    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
  }

  .ng-scrollbar {
    max-height: $autocomplete-menu-max-height;
  }
}
